  import React, { useRef } from 'react';
  import emailjs from '@emailjs/browser';
  import './contactForm.css';

  export const ContactForm = () => {
    const form = useRef();
    
    
    const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_jo3im3f', 'template_vg5pbyj' , form.current, '2vzUBFn38k2YvRzZ0')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
      <div class="contactformopicontainer">
      <div class="contactformopiimage">
        <img src="https://static.vecteezy.com/system/resources/previews/013/436/173/original/hand-drawn-doodle-customer-service-agent-with-laptop-and-phone-illustration-vector.jpg" alt="Image" />
      </div>
      <div class="contactformopiform-container">
      <form ref={form} className="form" onSubmit={sendEmail}>
        <center className='form_title'>Contact Us Form</center>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          required
        />

        <label htmlFor="number">Contact Number:</label>
        <input
          type="text"
          id="number"
          name="number"
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          required
        />

        <label htmlFor="company">Company:</label>
        <input
          type="text"
          id="company"
          name="company"
        />

        <label htmlFor="designation">Designation:</label>
        <input
          type="text"
          id="designation"
          name="designation"
        />

        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          required
        ></textarea>

        <label htmlFor="isWhatsApp">WhatsApp number:</label>
        <input
          type="checkbox"
          id="isWhatsApp"
          name="isWhatsApp"
        />
        <br />
        <button onClick={sendEmail} type="submit">Submit</button>
      </form>
      </div>
    </div>
  
    );
  };

  export default ContactForm;