const BlogCard2 = () => {
    return (
      <div className="max-w-[400px] mt-10 rounded-xl bg-gray-200 overflow-hidden shadow-xl shadow-gray-400">
        <div className="w-full flex flex-col items-center">
          <div className="w-full overflow-hidden">
          <img
            src="https://res.cloudinary.com/coderammer/image/upload/v1690582072/OPI_Database_Virtual/Insight/Articles/Blog2.png"
            alt=""
            className="w-full object-cover"
          />
          </div>
          <div className="flex flex-col items-start p-5 md:p-10">
            <span className="font-bold md:text-[24px] font-inter text-black">
            Achieving our Development and Climate Goals: Health First
            </span>
            <span className="mt-2 md:mt-5 text-[12px] md:text-[16px] font-jose">
            Triple planetary crises of climate change, loss of biodiversity, and escalating pollution have fueled a catastrophic cascade of events, negatively impacting our shared progress towards a more sustainable and equitable world. 

"Health for All" cannot be sustainably delivered in a world where temperatures exceed pre-industrial levels by 1.5°C. We highlight the importance and steps to achieve our combined ambitions of the Paris Agreement, Universal Health Coverage (UHC), Agenda 2030 while adopting a One Health approach.
            </span>
            <span className="bg-pblue py-2 px-6 mt-5 rounded text-white font-inter text-[14px] font-bold cursor-pointer hover:scale-110 transition-all">
              <a href="https://a7417d2b-55d4-4ef2-8dd3-353638d1dc43.usrfiles.com/ugd/a7417d_4961d9f0e76e4ba49b2d38ea65f111f6.pdf">Read More</a>
            </span>
          </div>
        </div>
      </div>
    );
  };
  
  export default BlogCard2;
  