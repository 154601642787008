import React, { useEffect, useRef } from 'react';
import './ScrollAnimation.css';

const ScrollAnimation = () => {
    const imagesRef = useRef([]);
  
    useEffect(() => {
      const handleScroll = () => {
        imagesRef.current.forEach((image) => {
          const imagePosition = image.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;
  
          if (imagePosition < windowHeight * 0.8) {
            image.classList.add('animate');
          } else {
            image.classList.remove('animate');
          }
        });
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      <div className="container_scroll">
        <div ref={(el) => (imagesRef.current[0] = el)} className="image">
          <img src="https://res.cloudinary.com/coderammer/image/upload/v1688638424/OPI%20_Freelancer%20Project/image1.jpg" alt="Image 1" />
        </div>
        <div ref={(el) => (imagesRef.current[1] = el)} className="image">
          <img src="https://res.cloudinary.com/coderammer/image/upload/v1688638424/OPI%20_Freelancer%20Project/image2.jpg" alt="Image 2" />
        </div>
        <div ref={(el) => (imagesRef.current[2] = el)} className="image">
          <img src="https://res.cloudinary.com/coderammer/image/upload/v1688638424/OPI%20_Freelancer%20Project/image3.jpg" alt="Image 3" />
        </div>
        <div ref={(el) => (imagesRef.current[3] = el)} className="image">
          <img src="https://res.cloudinary.com/coderammer/image/upload/v1688638424/OPI%20_Freelancer%20Project/image4.jpg" alt="Image 4" />
        </div>
        <div ref={(el) => (imagesRef.current[4] = el)} className="image">
          <img src="https://res.cloudinary.com/coderammer/image/upload/v1688638424/OPI%20_Freelancer%20Project/image5.jpg" alt="Image 5" />
        </div>
        <div ref={(el) => (imagesRef.current[5] = el)} className="image">
          <img src="https://res.cloudinary.com/coderammer/image/upload/v1688643848/OPI%20_Freelancer%20Project/image6.jpg" alt="Image 6" />
        </div>
        <br></br>
      </div>
    );
  };
  

export default ScrollAnimation;