import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const FounderSection = () => {
  let sectionRef = useRef(null);
  let triggerRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(".trigger", {
        scrollTrigger: {
          trigger: triggerRef,
          scrub: 1,
          start: "top top",
          end: "bottom bottom",
          pin: sectionRef,
        },
      });
      gsap.to(".sireesha-image", {
        scale: 1.3,
        scrollTrigger: {
          trigger: "#sireesha-right",
          scrub: 5,
          start: "top 20%",
          end: "bottom top",
        },
      });
    });
    return () => ctx.revert();
  }, []);

  return (
    <div className="w-full flex justify-center bg-oblue">
      <div
        ref={(el) => (triggerRef = el)}
        className="hidden md:flex justify-start w-full max-w-[1366px] trigger overflow-hidden"
      >
        <div
          ref={(el) => (sectionRef = el)}
          id="left"
          className="w-1/2 p-16 flex flex-col items-center overflow-hidden bg-oblue sticky"
        >
          <span className="w-full text-center font-inter font-bold text-black text-[32px]">
            Managing Partner
          </span>
          <span className="w-full text-center font-inter font-bold text-white text-[22px]">
            Sireesha Perabathina
          </span>
          <span className="flex justify-center w-2/3 rounded mt-10 overflow-hidden">
            <img
              src="/images/png/sireesha.png"
              className="sireesha-image shadow-lg shadow-black"
            />
          </span>
        </div>
        <div
          id="sireesha-right"
          className={`w-1/2 py-32 px-32 flex flex-col overflow-hidden bg-oblue font-jose text-[20px] text-black`}
        >
          Sireesha is the founder and managing partner of Open for Policy and Impact. She is a seasoned global health professional with over two decades of experience working with the leadership team of global foundations, social organizations, government departments, and corporates across India, Southeast Asia, Africa, Latin America, North America, and Europe. She is passionate about solving global social problems through enabling and building critical partnerships and networks.  Driven by her passion for addressing global social problems, Sireesha is dedicated to enabling and constructing vital partnerships and networks. She understands that tackling complex challenges requires the collective effort of diverse stakeholders, and she excels at bringing them together to achieve impactful outcomes. With a deep understanding of the intricacies of global health and social issues, Sireesha leverages her experience to drive positive change. She recognizes the importance of policy frameworks, strategic planning, and collaborative initiatives to effect meaningful transformations.
          <br />
          <br />
          Sireesha's vision is to create a world where critical partnerships and networks are the catalysts for solving global social problems. She believes that by fostering an environment of collaboration and leveraging diverse stakeholders' collective expertise and resources, we can tackle the most pressing challenges facing societies worldwide. Through her leadership at Open for Policy and Impact, Sireesha aims to bridge sectors, organizations, and geographies, enabling them to work together toward sustainable solutions. Her commitment to building partnerships and networks stems from her unwavering belief that collective action is the key to achieving a more equitable and prosperous world. Sireesha's work is driven by her unwavering dedication to social impact and her passion for creating positive change. Ultimately, our vision is to foster a future that is not only technologically advanced but also socially equitable and inclusive. 
          <br />
          <br />
          As the managing partner at OPI, she is responsible for the organization's overall strategic direction, growth, and impact. Sireesha leads several collective impact initiatives across domains within healthcare and beyond such as family welfare, sexual & reproductive health, maternal, neonatal & child health nutrition, health financing, digital transformation, financial inclusion, gender equity, quality, patient safety, and engagement. 
          <br />
          <br />
          Before founding OPI, Sireesha was in several leadership and consulting roles in a variety of organizations ranging from non-profits, think tanks, the private sector, government, and academic institutions nationally and internationally. She successfully executed strategic plans to implement several programs across domains.  She demonstrated cross-cultural savviness, the ability to communicate with diverse audiences, and managed cross-functional teams and partnerships across global.
          <br />
          <br />
          Sireesha is a Ford Foundation Fellow, 2005 Cohort from India, a postgraduate in healthcare management from the Indian Institute of Healthcare Management and Research, Jaipur, Rajasthan, and holds a master’s in public health from Boston University, School of Public Health, Boston Massachusetts, USA.
        </div>
      </div>
      <div className="flex flex-col items-center md:hidden p-10 bg-oblue py-10">
        <span className="w-full text-center font-inter font-bold text-black text-[28px] md:text-[32px]">
          Managing Partner
        </span>
        <span className="w-full text-center font-inter font-bold text-white text-[18px] md:text-[22px]">
          Sireesha Perabathina
        </span>
        <span className="flex justify-center md:w-2/3 rounded mt-10 overflow-hidden">
          <img
            src="/images/png/sireesha.png"
            className="sireesha-image shadow-lg shadow-black"
          />
        </span>
        <div
          id="sireesha-right"
          className={`font-jose text-[14px] md:text-[16px] md:text-center mt-10`}
        >
          Sireesha is the founder and managing partner of Open for Policy and Impact. She is a seasoned global health professional with over two decades of experience working with the leadership team of global foundations, social organizations, government departments, and corporates across India, Southeast Asia, Africa, Latin America, North America, and Europe. She is passionate about solving global social problems through enabling and building critical partnerships and networks.  Driven by her passion for addressing global social problems, Sireesha is dedicated to enabling and constructing vital partnerships and networks. She understands that tackling complex challenges requires the collective effort of diverse stakeholders, and she excels at bringing them together to achieve impactful outcomes. With a deep understanding of the intricacies of global health and social issues, Sireesha leverages her experience to drive positive change. She recognizes the importance of policy frameworks, strategic planning, and collaborative initiatives to effect meaningful transformations.
          <br />
          <br />
          Sireesha's vision is to create a world where critical partnerships and networks are the catalysts for solving global social problems. She believes that by fostering an environment of collaboration and leveraging diverse stakeholders' collective expertise and resources, we can tackle the most pressing challenges facing societies worldwide. Through her leadership at Open for Policy and Impact, Sireesha aims to bridge sectors, organizations, and geographies, enabling them to work together toward sustainable solutions. Her commitment to building partnerships and networks stems from her unwavering belief that collective action is the key to achieving a more equitable and prosperous world. Sireesha's work is driven by her unwavering dedication to social impact and her passion for creating positive change. Ultimately, our vision is to foster a future that is not only technologically advanced but also socially equitable and inclusive. 
          <br />
          <br />
          As the managing partner at OPI, she is responsible for the organization's overall strategic direction, growth, and impact. Sireesha leads several collective impact initiatives across domains within healthcare and beyond such as family welfare, sexual & reproductive health, maternal, neonatal & child health nutrition, health financing, digital transformation, financial inclusion, gender equity, quality, patient safety, and engagement. 
          <br />
          <br />
          Before founding OPI, Sireesha was in several leadership and consulting roles in a variety of organizations ranging from non-profits, think tanks, the private sector, government, and academic institutions nationally and internationally. She successfully executed strategic plans to implement several programs across domains.  She demonstrated cross-cultural savviness, the ability to communicate with diverse audiences, and managed cross-functional teams and partnerships across global.
          <br />
          <br />
          Sireesha is a Ford Foundation Fellow, 2005 Cohort from India, a postgraduate in healthcare management from the Indian Institute of Healthcare Management and Research, Jaipur, Rajasthan, and holds a master’s in public health from Boston University, School of Public Health, Boston Massachusetts, USA.
        </div>
      </div>
    </div>
  );
};

export default FounderSection;
