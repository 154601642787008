import React from "react";

const Newsletter = () => {
  return (
    <div className="flex items-center justify-center w-full mt-20">
      <span className="md:w-1/5 font-inter uppercase font-bold transition-all cursor-pointer hover:scale-110 hover:underline">
      <a href="https://global.us21.list-manage.com/subscribe/post?u=4239302e25a322e8e4f28854d&amp;id=6dc9bbe3cf&amp;f_id=00c65de1f0">Subscribe to our newsletter</a>
      </span>
    </div>
  );
};

export default Newsletter;
