const BlogCard3 = () => {
    return (
      <div className="max-w-[400px] mt-10 rounded-xl bg-gray-200 overflow-hidden shadow-xl shadow-gray-400">
        <div className="w-full flex flex-col items-center">
          <div className="w-full overflow-hidden">
          <img
            src="https://res.cloudinary.com/coderammer/image/upload/v1690582054/OPI_Database_Virtual/Insight/Articles/Blog3.png"
            alt=""
            className="w-full object-cover"
          />
          </div>
          <div className="flex flex-col items-start p-5 md:p-10">
            <span className="font-bold md:text-[24px] font-inter text-black">
            Empowering Women through Gender-Sensitive Financial Inclusion Strategies
            </span>
            <span className="mt-2 md:mt-5 text-[12px] md:text-[16px] font-jose">
            The triple crises of climate change, biodiversity loss, and pollution have led to catastrophic consequences, hindering progress towards a sustainable and equitable world. Achieving "Health for All" is not feasible in a world with temperatures exceeding 1.5°C above pre-industrial levels. 
            To address these challenges, we emphasize the significance of aligning the Paris Agreement, Universal Health Coverage (UHC), and Agenda 2030 goals while adopting a One Health approach.
            </span>
            <span className="bg-pblue py-2 px-6 mt-5 rounded text-white font-inter text-[14px] font-bold cursor-pointer hover:scale-110 transition-all">
              <a href="https://a7417d2b-55d4-4ef2-8dd3-353638d1dc43.usrfiles.com/ugd/a7417d_d60886adf3c14edca1e77f0551b95ef8.pdf">Read More</a>
            </span>
          </div>
        </div>
      </div>
    );
  };
  
  export default BlogCard3;
  