const BlogCard4 = () => {
    return (
      <div className="max-w-[400px] mt-10 rounded-xl bg-gray-200 overflow-hidden shadow-xl shadow-gray-400">
        <div className="w-full flex flex-col items-center">
          <div className="w-full overflow-hidden">
          <img
            src="https://res.cloudinary.com/coderammer/image/upload/v1690582062/OPI_Database_Virtual/Insight/Articles/Blog4.png"
            alt=""
            className="w-full object-cover"
          />
          </div>
          <div className="flex flex-col items-start p-5 md:p-10">
            <span className="font-bold md:text-[24px] font-inter text-black">
            Ayushman Bharat Digital Mission (ABDM) Adoption- Challenges and Opportunities 
            </span>
            <span className="mt-2 md:mt-5 text-[12px] md:text-[16px] font-jose">
            India's healthcare is swiftly digitizing, driven by the COVID-19 pandemic. The National Health Policy 2017 promotes digital tools for efficient healthcare. Initiatives like National Digital Health Blueprint 2019 and Ayushman Bharat Digital Mission (ABDM) support Universal Health Coverage. Challenges remain with infrastructure, rural digital literacy, and data privacy. To boost ABDM, private sector involvement, standardization, and behavior change among providers are vital. 
            </span>
            <span className="bg-pblue py-2 px-6 mt-5 rounded text-white font-inter text-[14px] font-bold cursor-pointer hover:scale-110 transition-all">
              <a href="https://a7417d2b-55d4-4ef2-8dd3-353638d1dc43.usrfiles.com/ugd/a7417d_10cfeed5998c4970ac985d17e7ee5851.pdf">Read More</a>
            </span>
          </div>
        </div>
      </div>
    );
  };
  
  export default BlogCard4;
  