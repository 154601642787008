import { Power3, gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AboutIntro = () => {
  const ease = Power3.easeOut();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let ctx = gsap.context(() => {
      gsap.fromTo(
        ".aaaabout-about-image",
        {
          yPercent: 20,
          scale: 1,
        },
        {
          yPercent: -20,
          scale: 2,
          ease: ease,
          scrollTrigger: {
            trigger: ".aaaabout-about-image",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    });
    return () => ctx.revert();
  });

  return (
    <div className="w-full flex justify-centerz">
      <div className="flex flex-col md:flex-row justify-between items-center max-w-[1366px] w-full">
        <div className="w-full h-[10vh] flex md:hidden items-center justify-between px-5 py-3">
          <img
            onClick={() => navigate("/")}
            src="https://res.cloudinary.com/coderammer/image/upload/v1688846874/OPI_Database_Virtual/Common_image/union.png"
            alt=""
            className="h-[40px]"
          />
          {showMenu}
          <div
            onClick={() => setShowMenu(!showMenu)}
            className=" cursor-pointer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Hamburger_icon.svg/1024px-Hamburger_icon.svg.png"
              alt=""
              className="h-[20px]"
            />
          </div>
        </div>
        <div
          className={
            showMenu
              ? "h-full bg-white flex md:hidden flex-col space-y-4 items-center justify-center"
              : "hidden"
          }
        >
          <span
            onClick={() => {
              setShowMenu(false);
              navigate("/");
            }}
            className="text-black font-mont text-[22px] font-bold"
          >
            Home
          </span>
          <span
            onClick={() => {
              setShowMenu(false);
              navigate("/about");
            }}
            className="text-black font-mont text-[22px] font-bold"
          >
            Who We Are
          </span>
          <span
            onClick={() => {
              setShowMenu(false);
              navigate("/services");
            }}
            className="text-black font-mont text-[22px] font-bold"
          >
            What We Do
          </span>
          <span
            onClick={() => {
              setShowMenu(false);
              navigate("/insights");
            }}
            className="text-black font-mont text-[22px] font-bold"
          >
            Insights
          </span>
          <span
            onClick={() => {
              setShowMenu(false);
              navigate("/contact");
            }}
            className="text-black font-mont text-[22px] font-bold"
          >
            Contact Us
          </span>
        </div>
        <div className="flex flex-row md:flex-col justify-center items-start md:w-1/2 max-w-[600px] md:pl-20 px-5 md:px-0">
          <span className="w-1/3 text-[22px] flex md:hidden pr-5 text-right font-extrabold font-inter text-ipink">
            {`About Us`}
          </span>
          <div className="flex flex-col md:w-2/3">
            <span className={`text-[18px] font-jose w-full`}>
            At OPI, we believe that policy and impact go hand in hand. Our multidisciplinary team brings together diverse backgrounds, ranging from policy analysis and advocacy to project management and technology, enabling us to tackle complex societal issues with a holistic approach.
              <br />
              <br />
              We leverage our deep understanding of local contexts and global best practices to design tailored strategies that address the most pressing challenges of our time.
            </span>
          </div>
        </div>
        <div className="w-1/2 h-[80vh] hidden md:flex items-center justify-center m-10 overflow-hidden">
          <img
            src="https://res.cloudinary.com/coderammer/image/upload/v1688842822/OPI_Database_Virtual/Who_we_are/about_us_intro.png"
            className="object-cover w-4/5 my-20 object-center overflow-hidden h-full aaaabout-about-image"
          />
        </div>
        <div className="md:w-1/2 flex md:hidden items-center px-5 justify-center mt-10 md:mt-0 overflow-hidden">
          <img
            src="https://res.cloudinary.com/coderammer/image/upload/v1688842822/OPI_Database_Virtual/Who_we_are/about_us_intro.png"
            className="object-cover object-center overflow-hidden h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutIntro;
