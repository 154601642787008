import React from "react";
import ScrollAnimation from './ScrollAnimation';
import './graphhomepage.css';
const Graphhomepage = () => {
 
  return (
    
    <div className="margin flex flex-col items-center justify-center w-full bg-black py-12">
      <span className="margin font-inter font-bold md:text-[40px] text-ipink">
        Our Analysis
      </span>
      <span className="margin md:text-[1.2em] font-jose text-white mt-5 text-center mx-10 md:mx-0">
      Aligning with the Global South for amplified scalability, revenue, and impact of solutions

      </span>
      <div className=" margin graph mt-5 max-w-[1800px]">
        <ScrollAnimation />
      </div>
    </div>
  );
};

export default Graphhomepage;
