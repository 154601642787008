import React, { useState, useEffect } from "react";
import useContentful from "../../hooks/useContentful";
import BlogCard from "../../components/BlogCard";
import BlogCard2 from "../../components/BlogCard2";
import BlogCard3 from "../../components/BlogCard3";
import BlogCard4 from "../../components/BlogCard4";
import InsightsHero from "./InsightsHero";
import Footer from "../../components/Footer";
import LatestNews from "./LatestNews";
import '../globalopiab.css';
const InsightsPage = () => {
  const { getData } = useContentful();
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    getData().then((response) => {
      console.log("custom hook response", response.items);
      setPostData(response.items);
      console.log(postData, "lmfao");
    });
  }, []);

  useEffect(() => {
    console.log(postData, "lmfao");
  }, [postData]);

  return (
    <div className="flex flex-wrap justify-evenly">
      <InsightsHero />
      {/* <LatestNews /> */}
      <span className="text-ipink font-bold text-[20px] md:text-[38px] font-inter w-full text-center margin_tenpx">
      Our Research and <ul>Knowledge Products</ul>
            </span>
            <span className="text-black font-bold text-[10px] md:text-[18px] font-mont w-full text-center">
              Our knowledge creation drives partnerships and <br />catalyzes
              innovative solutions.
            </span>
      <div className="p-10 w-full flex flex-wrap justify-evenly">
        
        <BlogCard2/>
        <BlogCard3/>
        <BlogCard4/>
      </div>
      <Footer />
      
    </div>
  );
};

export default InsightsPage;
